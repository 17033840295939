(function ($) {

  $(document).ready(function() {
    if($('#wideLayoutTransform').length != 0) {
        var aside = $('#content').prev('aside');
        // bounce to full width column
        aside.attr('class', 'aside-below').insertAfter('#content');
        $('#content').find('>:first-child').attr('class', 'col-sm-12');

        // count how many items in sidebar 2 or 3
        var items = aside.find('section');
        var num = items.length;
        if (num == 3) {
          items.addClass('col-sm-4');
          if(items.hasClass("block-style-2")){
            items.css("padding","10px");
          }
        } else {
          items.addClass('col-sm-6');
          if(items.hasClass("block-style-2")){
            items.css("padding","10px");
          }
        }
    }

      if($('#permit-required').length != 0) {
        var selectItem = $('#edit-submitted-investigation-type-permit-required');
        var toggleItem = $('.form-item-submitted-investigation-type-permit-number');

        toggleItem.hide();
        $( selectItem ).change(function() {
          if(selectItem.val() == 'true') {
            toggleItem.show();
          }else{
            toggleItem.hide();
          }
        });

      }

      if($('.media-element-container').length != 0) {
        $( "img.media-image" ).each(function() {
          var mediaFloat = $( this ).css('float');
          var mediaInline = $( this ).css('display');
          var container = $( this ).closest('div.media-element-container');
          if(mediaFloat == 'right' || mediaFloat == 'left') {
            $( container ).addClass('media-wysiwyg-align-'+ mediaFloat);
          }
          if(mediaInline == 'inline-block' || mediaInline == 'inline') {
            $( container ).css('display', 'inline-block');
          }
        });
      }

      if($('a > .media-element-container').length != 0) {
        $( 'a > .media-element-container' ).each(function() {
          $( this ).css('margin', '.5em');
        });
      }

      $('a').each(function () {
        if (this.href.indexOf('online.nhmu.utah.edu') > -1) {
          $(this).click(function (event) {
            event.preventDefault();
            event.stopPropagation();
            window.open(this.href, '_blank');
          });
        }
      });

  }),


  // load complete
  $(window).load(function() {
    if ($('body').width() > 767) {
      $('body').removeClass('mobile-width');
    } else {
      $('body').addClass('mobile-width');
    }
    $(window).resize(function () {
      if ($('body').width() > 767) {
        $('body').removeClass('mobile-width');
      } else {
        $('body').addClass('mobile-width');
      }
    });
    googleTranslateElementInit();


    $("body").fadeIn(100);// Hackish to show image afterlaod
    var imageSelector = $('.views-slideshow-cycle-main-frame-row-item > div .field-content img');
    var containerSelector = $('.views_slideshow_cycle_main.views_slideshow_main > div');
    var captionSelector = $('.main>.container .slideshow-caption.box_bottom_left a, .main>.container .slideshow-caption.box_bottom_right a, .main>.container .slideshow-caption.box_top_left a, .main>.container .slideshow-caption.box_top_right a');

    $('.view-Slideshow').each(function () {
      var parentWidth = $(this).width();
      var height = slideHeight(parentWidth, 2.986);
      $(imageSelector).height(height);
      $(containerSelector).height(height);
      $(containerSelector).width(parentWidth);
    });

    $(window).resize(function () {
      $('.view-Slideshow').each(function () {
        var parentWidth = $(this).width();
        var height = slideHeight(parentWidth, 2.986);
        $(imageSelector).height(height);
        $(containerSelector).height(height);
        $(containerSelector).width(parentWidth);
      });
    });
    $('.view-newsdesk-slideshows').each(function () {
      var parentWidth = $(this).width();
      var height = slideHeight(parentWidth, 2.038);
      $(imageSelector).height(height);
      $(captionSelector).height(height - 19);
      $(containerSelector).height(height);
      $(containerSelector).width(parentWidth);
    });

    $(window).resize(function () {
      $('.view-newsdesk-slideshows').each(function () {
        var parentWidth = $(this).width();
        var height = slideHeight(parentWidth, 2.038);
        $(imageSelector).height(height);
        $(captionSelector).height(height - 19);
        $(containerSelector).height(height);
        $(containerSelector).width(parentWidth);
      });
    });
    function slideHeight(length, ratio) {
      var height = length/ratio ;
      return Math.round(height);
    }

  	// Window is changing
    $(window).resize(function () {

      if ($('body').width() > 767) {
          //$('body').css('left','initial');
          $('#slide-nav .navbar-toggle').css('left','10px');
        }else{
        var selected = $('#slide-nav').hasClass('slide-active');
        if(selected){
          //$('body').css('left','80%');
          $('#slide-nav .navbar-toggle').css('left','90%');
        }else{
          //$('body').css('left','initial');
          $('#slide-nav .navbar-toggle').css('left','10px');
        }
      }
    });


      $(".cart").popover({
        trigger: "manual",
          html : true,
          content: function() {
            return $('#cart-content').html();
          }
      }).on("click", function(e) {
        e.preventDefault();
      }).on("mouseenter", function() {
        var _this = this;
        $(this).popover("show");
        $(this).siblings(".popover").on("mouseleave", function() {
          $(_this).popover('hide');
        });
      }).on("mouseleave", function() {
        var _this = this;
        setTimeout(function() {
          if (!$(".popover:hover").length) {
            $(_this).popover("hide")
          }
        }, 100);
      });

      $(".cart").click(function(){
        window.location.href = '/cart';
      });
      //stick in the fixed 100% height behind the navbar but don't wrap it
      $('#slide-nav.navbar-inverse').after($('<div class="inverse" id="navbar-height-col"></div>'));

      $('#slide-nav.navbar-default').after($('<div id="navbar-height-col"></div>'));

      // Enter ids or classes
      var toggler = '.navbar-toggle';
      var pagewrapper = '#page-content';
      var navigationwrapper = '.navbar-header';
      var menuwidth = '100%'; // the menu inside the slide menu itself
      var slidewidth = '100%';
      var menuneg = '-100%';
      var slideneg = '-100%';


      $("#slide-nav").on("click", toggler, function (e) {

          var selected = $(this).hasClass('slide-active');

          $('#primary-navigation').stop().animate({
              left: selected ? menuneg : '0px'
          });

          $('#navbar-height-col').stop().animate({
              left: selected ? slideneg : '0px'
          });

          $(this).toggleClass('slide-active', !selected);
          $('#primary-navigation').toggleClass('slide-active');

          $('#page-content, #slide-nav, .navbar, body, .navbar-header').toggleClass('slide-active');

          if(selected){
            $('body').animate({'left':'0'}, 400);
            $('#slide-nav .navbar-toggle').animate({'left':'10px'}, 400);
          }else{
            //$('body').animate({'left':'80%'}, 400);
            $('#slide-nav .navbar-toggle').animate({'left':'90%'}, 400);
          }

      });

    /**
     * Disable Desktop-Menu default click events
     * Only allow the submenu to link out
     * Added Aria Tags for Screen reader Accessibility
     */
    var desktopNav = $('body:not(.mobile-width) .main-menu ul.menu');
    var mobileNav = $('body.mobile-width .main-menu ul.menu');


    // Hover resets focus // Mostly cosmetic to not leave drops open when using keyboard.
    desktopNav.find('> li > a').hover( function() {
       // $(this).focus();
    });
    desktopNav.find('> li > a').focusin( function() {
       desktopNav.find('> li').trigger('mouseleave');
    });

    desktopNav.find('> li.dropdown').each( function(){
        var container = $(this);
        var link = $(this).find('a.dropdown-toggle');
        var submenu = $(this).find('ul.dropdown-menu');

        container.mouseenter( function(){
          // link.focus();
          link.attr( 'aria-expanded', 'true');
          submenu.addClass('open');
        });

        container.mouseleave( function(){
          link.attr( 'aria-expanded', 'false');
          submenu.removeClass('open');
        });

        link.click( function(e){
          $(this).attr( 'aria-expanded', 'true');
          submenu.addClass('open');
          e.stopPropagation();
          return false;
        });
    });

    mobileNav.find('> li.dropdown').each( function(){
        var container = $(this);
        var link = $(this).find('a.dropdown-toggle');
        var submenu = $(this).find('ul.dropdown-menu');


        link.click( function(e){
          $(this).attr( 'aria-expanded', 'true');
          submenu.addClass('open');
          mobileNav.find('> li > ul.dropdown-menu').not(submenu).slideUp(200);
          submenu.stop(true, true).slideToggle(200);
          e.stopPropagation();
          return false;
        });
    });


      /**
       * Newsdesk display mods
       */
      $('body.node-type-newsdesk-expert h1.content-title').detach().prependTo('.field-name-field-position-title > .field-items');


  });
})(jQuery);
